
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PasswordRequirement from '@/routes/user/PasswordRequirement.vue'

type Test =
  | 'long'
  | 'noWhitespace'
  | 'noNonPrintable'
  | 'uppercase'
  | 'lowercase'
  | 'number'
  | 'special'
  | 'threeOutOfFour'

type Tests = {
  [k in Test]: boolean
}

@Component({
  name: 'PasswordResetRoute',
  components: { Requirement: PasswordRequirement },
})
export default class extends Vue {
  @Prop()
  password: string

  @Prop({ required: true })
  showErrorState: boolean

  get tests() {
    // Copied directly from the CMS PasswordRequirements.vue file
    // https://github.com/stqry/tix-unicity/blob/main/components/reset-password-form/src/PasswordRequirements.vue#L32
    // TODO: Replace this with a shared function between eComm and the CMS
    const password = this.password || ''

    let out: Tests = {
      long: password.length >= 8,
      noWhitespace: !password.startsWith(' ') && !password.endsWith(' '),
      noNonPrintable: !/[^ -~]/.test(password),
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/.test(password),
      threeOutOfFour: false,
    }

    out['threeOutOfFour'] =
      Number(out.uppercase) + Number(out.lowercase) + Number(out.number) + Number(out.special) >= 3
    return out
  }

  get isValid() {
    return this.tests.long && this.tests.noWhitespace && this.tests.noNonPrintable && this.tests.threeOutOfFour
  }

  get notEmpty() {
    return this.password && this.password.length > 0
  }

  @Watch('tests', { deep: true })
  emitValue() {
    this.$emit('input', this.isValid)
  }
}
