
import FormInput2 from '@/components/forms/FormInput2.vue'
import { authenticationMethod } from '@/helpers/Environment'
import EventBus from '@/helpers/EventBus'
import { reportFormValidity } from '@/helpers/Validation'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import { API } from '@/api/API'
import PasswordRequirements from '@/routes/user/PasswordRequirements.vue'

@Component({
  name: 'PasswordResetRoute',
  components: { PasswordRequirements, FormInput2 },
})
export default class extends Vue {
  loading: boolean = true
  token: string | null = null
  notFoundError: string | null = null
  email: string | null = null
  password: string | null = null
  submitting: boolean = false
  success: boolean = false
  isValid: boolean = false
  showErrorState: boolean = false
  error: string = ''

  beforeRouteEnter(to, from, next) {
    // Go home if the user is logged in or the token query parameter is absent.
    next(store.getters['Member/user'] || !to.query.token ? '/' : undefined)
  }

  created() {
    EventBus.$on('tix:login:success', () => {
      // Go home once the user becomes logged in.
      this.$router.push('/')
    })

    this.token = this.$route.query.token

    API.post<'identity'>('password_reset/validate', { body: { token: this.token } })
      .then((response) => {
        this.email = response.identity._data[0].email
      })
      .catch((error) => {
        const code = error.response?.data._data[0]._code
        if (code === 'not_found' || code === 'validate_failed') {
          this.notFoundError = 'This reset link is not valid. It may have expired.'
        } else {
          // Unexpected error. Log the error.
          throw error
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  validate() {
    this.error = this.isValid ? '' : 'Invalid password'
  }

  submit() {
    this.showErrorState = !this.isValid

    reportFormValidity(this.$refs.form as HTMLFormElement)
      .then(() => {
        this.submitting = true
      })
      .then(() => {
        return API.post('password_reset/reset', {
          body: {
            token: this.token,
            password: this.password,
          },
        })
      })
      .then(() => {
        this.success = true
      })
      .catch((e) => {
        if (e.validationError) {
          // Do nothing.
        } else {
          throw e
        }
      })
      .finally(() => {
        this.submitting = false
      })
  }

  get authenticationMethod() {
    return authenticationMethod
  }
}
