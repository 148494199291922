
import { Component, Vue } from 'vue-property-decorator'
import Price from '@/components/elements/Price.vue'
import store from '@/store/store'

@Component({
  components: {
    Price,
  },
})
export default class MobileTotal extends Vue {
  get paymentDueByCreditCard() {
    return store.getters['Cart/paymentDueByCreditCard']
  }
}
