import { environment } from '@/helpers/Environment'
import { getTotalFeesIfEnabled } from '@/helpers/Fees'
import type { Session } from '@/types/Sessions'

export interface ShowPricesConfig {
  dates: boolean
  sessions: boolean
}

export interface PricedItem<T extends Session | EventDateData> {
  key: string
  soldOut: boolean
  value: T
}

export interface PricingType {
  id: string
  prefix?: string
}

// Always show prices on ticket quantity steppers.
export function showPrices(types: TicketType[]): ShowPricesConfig {
  // Disable price display if there are any variable-priced ticket types.
  // Date prices are estimated differently than session prices for variable-priced TTs, and it is not a valid use case.
  if (types.some((tt) => tt.currency_amount_max)) {
    return { dates: false, sessions: false }
  }

  const config = environment.web.price_display?.enabled

  if (config === 'all') {
    return { dates: true, sessions: true }
  } else {
    return { dates: false, sessions: config === 'sessions_and_tickets' }
  }
}

export function sessionsWithPrices(sessions: Session[], priceSchedules: PriceSchedule[]): Session[] {
  return mergeSessionsAndPriceSchedules(sessions, priceSchedules, (session, priceSchedule) => {
    const fees = getTotalFeesIfEnabled(priceSchedule?.outside_fees)
    return {
      ...session,
      price: Number(priceSchedule?.currency_amount) + fees,
      maxPrice: Number(priceSchedule?.currency_amount_max),
      minPrice: Number(priceSchedule?.currency_amount_min),
    }
  })
}

export function sessionsWithPriceIncrease(
  sessions: Session[],
  priceSchedules: PriceSchedule[],
  currentScheduledPrice: number,
): Session[] {
  return mergeSessionsAndPriceSchedules(sessions, priceSchedules, (session, priceSchedule) => ({
    ...session,
    priceIncrease: Number(priceSchedule?.currency_amount) - currentScheduledPrice,
  }))
}

function mergeSessionsAndPriceSchedules<T extends Session>(
  sessions: Session[],
  schedules: PriceSchedule[],
  callback: (session: Session, schedule: PriceSchedule) => T,
): Array<Session | T> {
  let i = 0
  return sessions.map((session) => {
    if (session.sold_out) return session
    else {
      // The API guarantees that price schedules contain all _available_ sessions and do not overlap each other.
      while (!session.startTime.isBetween(schedules[i].from, schedules[i].to, undefined, '[)')) {
        i++
      }
      return callback(session, schedules[i])
    }
  })
}

export async function fetchPricedItems<T extends Session | EventDateData>(pricingTypes: readonly PricingType[], fetch: (ticketTypeId: string) => Promise<PricedItem<T>[]>): Promise<T[]> {
  if (pricingTypes.length === 0) {
    throw new Error('No pricing types provided.')
  }

  const soldOut = new Set<string>()
  const result: Dict<PricedItem<T>> = {}

  for (const type of pricingTypes) {
    for (const item of await fetch(type.id)) {
      const key = item.key
      if (!result[key] || soldOut.has(key)) {
        item.value.pricePrefix = type.prefix
        result[key] = item
        if (item.soldOut) soldOut.add(key)
        else soldOut.delete(key)
      }
    }
    if (soldOut.size < 1) {
      break
    }
  }

  return Object.values(result).map((item) => item.value)
}
