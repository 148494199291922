import ValidationError from '@/errors/ValidationError'
import { dispatchEvent } from '@/helpers/DispatchEvent'

export function reportFormValidity(form: HTMLFormElement): Promise<void> {
  return Promise.resolve().then(() => {
    if (form) {
      const fields = form.querySelectorAll('input, select')

      for (const field of fields as NodeListOf<HTMLInputElement | HTMLSelectElement>) {
        const valid = field.checkValidity()
        if (!valid) {
          dispatchEvent('invalid', field)
        } else {
          dispatchEvent('validate', field)
        }
      }

      // Get the first invalid field.
      const field = form.querySelector(':invalid') as HTMLInputElement
      if (field) {
        field.scrollIntoView()
        // Scroll up a bit more.
        window.scrollBy(0, -30)

        throw new ValidationError()
      }
    }
  })
}

export function formIsValid(form: HTMLFormElement): boolean {
  const fields = form.querySelectorAll('input, select')

  for (const field of fields as NodeListOf<HTMLInputElement | HTMLSelectElement>) {
    if (!field.validity.valid) {
      return false
    }
  }

  return true
}
