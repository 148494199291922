import { config, environment } from '@/helpers/Environment'
import { isTrueish } from '@/helpers/StringHelpers'
import { sum } from '@/helpers/Math'

export function getTotalFees(fees?: OutsideFee[]): number {
  if (!fees) return 0
  const result = fees.map((fee) => parseFloat(fee.fee))
  return sum(result)
}

export function getTotalFeesIfEnabled(fees?: OutsideFee[]): number {
  return showAdvertisedFees() ? getTotalFees(fees) : 0
}

export function showAdvertisedFees(): boolean {
  // TODO Use sellerConfig() once it supports the `config` namespace.
  const sellerConfig = environment.sellerMeta?.advertise_price_including_fees
  return Boolean(sellerConfig ? isTrueish(sellerConfig) : config.advertise_price_including_fees)
}
