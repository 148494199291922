
import { getMembershipRules, MembershipRules } from '@/api/Membership'
import { openDeleteCartOrCheckoutDialog } from '@/components/membership/DeleteCartOrCheckoutDialog.vue'
import { openLoginOrGuestDialog } from '@/components/membership/LoginOrGuestDialog.vue'
import MembershipForm, { type SubmitPayload } from '@/components/membership/MembershipForm.vue'
import { apiErrorMessageOrRethrow, cartCodeIsRequired, getApiErrorEntity, logInTrackJS } from '@/errors/helpers'
import { identifiedRoles, nameDelimiter } from '@/helpers/BuyMembershipHelpers'
import { mapEntries } from '@/helpers/DictHelpers'
import EventBus from '@/helpers/EventBus'
import { IdentityFormData, sanitizeIdentityMeta } from '@/helpers/IdentityHelpers'
import type { LanguageStrings } from '@/language/types'
import { isLoggedIn } from '@/state/Authentication'
import { addMembershipToCart, isCurrentMember } from '@/state/Membership'
import store from '@/store/store'
import type { ComponentOptions } from '@/types/ComponentOptions'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { API } from '@/api/API'
import { groupBy } from '@/helpers/IndexHelpers'
import { openCodeModal } from '@/modals/codeModal'
import type { EventDetails } from '@/api/types/processedEntities'

/**
 * TODO Move to ./membership/ directory or rename <ReserveMembership>?
 */
@Component({
  name: 'BuyMembership',
  components: {
    MembershipForm,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  event: EventDetails

  @Prop()
  contexts: Set<string>

  selectedLevel: string | null = null
  rules: MembershipRules | null = null
  details: Dict<MembershipAdmitDetails[]> = {}
  countsByTTID: Dict<number> = {}
  giftee: IdentityFormData = {}
  surveyAnswers: Dict<Primitive> = {}
  submitting = false
  extendMembership = false

  readonly formId = 'buy-membership-form'

  // Plain text error messages and content that may not be trusted.
  plainError: string | null = null

  // Templated error messages that may include content like
  //    <membership-link>join now<membership-link> for free tickets.
  templateError: string | null = null

  opt: ComponentOptions['buyMembership']
  t: LanguageStrings['buyMembership']

  created() {
    getMembershipRules().then((result) => {
      this.rules = result

      if (this.gifting) {
        Promise.resolve()
          .then(() => {
            if (store.getters['Cart/cartHasItems']) {
              return openDeleteCartOrCheckoutDialog({
                title: 'Gift a Membership',
                description: 'You need to take action on your current cart before you can gift a membership.',
              })
            }
          })
          .then(() => {
            if (!store.getters['Cart/cartHasItems'] && !isLoggedIn()) {
              openLoginOrGuestDialog()
            }
          })
      } else {
        const membership = store.getters['Cart/cartItems'].find((item) => item.isMembershipEvent)
        if (membership) {
          openDeleteCartOrCheckoutDialog({
            title: this.t.deleteCartOrCheckout.title,
            description: this.t.deleteCartOrCheckout.description,
          })
        }
      }

      if (this.contexts.has('renew')) {
        this.setMembershipDetails()

        EventBus.$on('tix:login:success', () => {
          if (this.selectedLevel == null) {
            this.setMembershipDetails()
          }
        })
      }
    })
  }

  get previousMembership(): { expiry: string; levelName: string } | null {
    if (!isCurrentMember()) {
      return null
    }

    const medium = this.$t('dateFormat.medium')
    return {
      expiry: store.getters['Member/membershipExpiry'].format(medium),
      levelName: store.getters['Member/membershipLevelName'],
    }
  }

  private setMembershipDetails() {
    if (isCurrentMember()) {
      // Filter out `codes` ticket groups and get TG IDs.
      const availableIDs = new Set(
        this.event.ticketGroups.filter((tg) => tg.handler === 'membership').map((group) => group.id),
      )

      // Is the previous membership level on offer?
      const tickets = store.getters['Member/tickets'] as BaseTicket[]
      const previousLevel = tickets.find((ticket) => availableIDs.has(ticket.ticket_group_id))
      if (previousLevel) {
        // Yes. Select it.
        this.selectedLevel = previousLevel.ticket_group_id
      }

      // Set details and counts of current members.
      const detailsByType: Dict<BaseTicket[]> = store.getters['Member/memberDetailsByType']
      this.countsByTTID = mapEntries(detailsByType, (details) => details.length)
      this.details = mapEntries(detailsByType, (details) =>
        details
          .filter((member) => member.admit_name?.length || member.admit_email?.length)
          .filter((member) => {
            // Ignore tickets with details if they aren't meant to be named.
            return this.rules!.rules.find((rule) => rule.ticket_type_id === member.ticket_type_id)?.named !== 'no'
          })
          .map((member) => {
            const [first, last] = member.admit_name?.split(nameDelimiter) ?? []
            const email = member.admit_email
            return { first, last, email }
          }),
      )
    }
  }

  @Watch('selectedLevel')
  rememberMemberDetails(_, lastTGID) {
    if (this.rules) {
      const allRules = this.rules.rules
      const lastSelectedRules = allRules.filter((rule) => rule.ticket_group_id === lastTGID)
      const lastSelected = groupBy('party_role', lastSelectedRules)

      for (const rule of allRules) {
        if (lastSelected[rule.party_role]) {
          // Multiple rules per party_role is probably a misconfiguration.
          if (
            lastSelected[rule.party_role].length > 1 &&
            lastSelected[rule.party_role].some((entity) => entity.named === 'yes')
          ) {
            logInTrackJS('Multiple membership rules for same party_role', lastSelected[rule.party_role])
          }

          // If there are multiple rules, use the first.
          // TODO Ignore rules that are not named.
          const last = lastSelected[rule.party_role][0]
          const details = this.details[last.ticket_type_id]
          if (details && rule.ticket_type_id !== last.ticket_type_id) {
            this.details[rule.ticket_type_id] = details
          }
        }
      }
    }
  }

  get identifiedRoles() {
    return identifiedRoles(this.rules!, this.selectedLevel!)
  }

  submit({ tickets, autoRenew, extendedMembership }: SubmitPayload, codes?: string[]): Promise<void> {
    // TODO Deduplicate this from RedeemMembershipRoute once this route no longer handles gifting.
    return this.upsertGiftee()
      .then(() => {
        const promoCodes = this.extendMembership && extendedMembership ? [extendedMembership.code] : []
        return addMembershipToCart(
          this.event!,
          this.rules!,
          this.selectedLevel!,
          promoCodes,
          tickets,
          this.surveyAnswers,
          autoRenew,
          codes,
        )
      })
      .then(() => {
        // Skip upsells when gifting memberships.
        if (this.gifting || this.opt.skipUpsells) {
          window.tixAppState.skipUpsells = true
        }
      })
      .then(() => {
        const reservedTicketGroups = this.event.ticketGroups.filter((tg) => tg.id === this.selectedLevel)
        this.$emit('done', reservedTicketGroups)
      })
      .catch((error) => {
        if (error.validationError) {
          // Ignore validation errors and leave the promise resolved.
        } else {
          const apiError = getApiErrorEntity(error)
          if (apiError && cartCodeIsRequired(apiError)) {
            openCodeModal(apiError._description, (code) => {
              return this.submit({ tickets, autoRenew, extendedMembership }, [code])
            })
          } else if (apiError?._code === 'code_not_found') {
            // Throw code_not_found errors so that <CodeModal> can handle it. <CodeModal> re-invokes the submit
            // handler via the second parameter to openCodeModal().
            throw error
          } else {
            // API error messages do not include HTML.
            this.plainError = apiErrorMessageOrRethrow(error, 'rule_blocks_reserve:checkout_rules,validate_not_email')
          }
        }
      })
      .finally(() => {
        this.submitting = false
      })
  }

  /**
   * @deprecated Will be replaced by Gift of Membership
   */
  get gifting() {
    return this.contexts.has('gift')
  }

  /**
   * @deprecated Will be replaced by Gift of Membership
   */
  private upsertGiftee(): Promise<void> {
    if (!this.gifting) {
      return Promise.resolve()
    }

    const user = store.getters['Member/user']
    if (user && user.email === this.giftee.email) {
      this.templateError =
        'You can not gift membership to yourself. Do you want to <membership-link>buy or renew membership</membership-link> for yourself?'
      return Promise.reject({ validationError: true })
    }

    const { first, last } = this.primaryMemberDetails
    return API.post('giftee', { body: this.gifteePayload }).then((response) => {
      // Checkout needs to know the giftee's;
      //   - ID in order to pass onto the API in order to assign the beneficiary correctly.
      //   - Name to display in the page title.
      store.commit('Cart/giftee', {
        // @ts-ignore /giftee has an unusually brief response.
        id: response.identity_id,
        name: `${first} ${last}`,
        email: this.giftee.email,
      })
    })
  }

  private get gifteePayload() {
    const { first, last } = this.primaryMemberDetails

    return {
      first_name: first,
      last_name: last,
      email: this.giftee.email,
      additional_info: sanitizeIdentityMeta(this.giftee),
    }
  }

  get showAutoRenew(): boolean {
    return !this.contexts.has('gift')
  }

  private get primaryMemberDetails(): MembershipAdmitDetails {
    const id = this.identifiedRoles[0].type.id
    return this.details[id][0]
  }

  get reserveButtonLabels() {
    return {
      desktop: this.t.reserveButton,
      mobile: this.t.reserveButton,
    }
  }

  get autoRenewalLabels() {
    return {
      title: this.t.autoRenewal,
      subtext: this.t.autoRenewalSubtext,
      yes: this.t.yes,
      no: this.t.no,
    }
  }
}
