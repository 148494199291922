import { csvToArray } from '@/helpers/StringHelpers'
import type { RawThemeConfig } from '@/themeConfig/types'
import type { ConfigYaml } from '@/types/ConfigYaml'
import type { AuthenticationMethodName, Env } from '@/types/Env'

export const environment: Env = getEnvironment()

// Export various frequently used variables explicitly for convenient access.
export const portal: Env['portal'] = environment.portal
export const config: Env['config'] = environment.config
export const timezone = environment.seller?.timezone ?? environment.portal.timezone
export const theme: Partial<RawThemeConfig> = environment.theme ?? {}
export const defaultLanguage: 'en' | 'es' = environment.webClient?.defaultLanguage ?? 'en'
export const defaultLedgerType = environment.ledgers?.config.default_type ?? 'giftcard'
export const authenticationMethod: AuthenticationMethodName | undefined = getAuthenticationMethod()
export const categoriesThatMayConflict = new Set(environment.web.conflict_avoidance?.exclude_categories)

/**
 * @deprecated Prefer config API.
 */
export const configYml: ConfigYaml = environment.webClient ?? {}

/**
 * @deprecated TODO Use .AppEnv or .AppEnvCode instead.
 * @see https://tixtrackteam.slack.com/archives/CP9C2ET5E/p1654582489303329
 * @see https://github.com/stqry/tix-web/blob/5c0d25d35c662138e4fa5b37844cbf49b1bbb60a/util/app.go#L13-L14
 */
export const environmentName = getEnvironmentName()

function getEnvironment(): Env {
  // Fallback on  a stub for unit tests.
  const result = {
    config: {},
    portal: {},
    web: {},
    captcha: {},
    portalStrings: {},
    // Leave theme and webClient undefined to use the Ticketure theme and fallback defaults.
    theme: undefined,
    webClient: undefined,
    ...window.tix,
  } as Env

  if (window.Cypress && window.__configPreprocessor) {
    return window.__configPreprocessor(result)
  } else {
    return result
  }
}

function getEnvironmentName() {
  const host = window.location.hostname

  const endings = 'localhost, .ngrok.io, .local.d1tix.com'
  if (csvToArray(endings).some((ending) => host.endsWith(ending))) {
    return 'local'
  } else if (
    host.endsWith('web.d1tix.com') ||
    host.endsWith('web.f1tix.co.uk') ||
    // E.g. famsf.web.use2.ticketuretest.com
    host.endsWith('.ticketuretest.com')
  ) {
    return 'd1tix'
  } else {
    // Default to production to be sure not to miss anything.
    return 'prod'
  }
}

function getAuthenticationMethod(): AuthenticationMethodName | undefined {
  // tix-api calls this a "grant type".
  if (environment.web.authentication_method !== 'none') {
    return environment.web.authentication_method
  }
}

export function logTicketureVersion() {
  const branch = environment.config.tix_web_app_version
  if (environmentName !== 'local' && branch) {
    const common = 'display: inline-block; padding: 5px 10px;'
    const firstStyle = common + 'background: navy; color: white; border-radius: 6px 0 0 6px;'
    const lastStyle = common + 'background: black; color: lime; border-radius: 0 6px 6px 0;'
    /* eslint-disable no-console */
    console.log(`%cTicketure version %c${branch}`, firstStyle, lastStyle)
    TrackJS?.addMetadata('git branch', branch)
  }
}

export function getLogoExternalUrl() {
  return environment.sellerMeta?.logo_external_url ?? environment.web.external_urls?.logo
}
