
import { API } from '@/api/API'
import { cartApiParams, CartApiResponse } from '@/api/Cart'
import { apiEntities, mergeConfigIntoEvents } from '@/api/Helpers'
import Price from '@/components/elements/Price.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import { isAnchor, isUpsell } from '@/helpers/Anchors'
import { fetchCartAndHandleExpired, fetchOrderAndCart, findStripeOrFreeFee } from '@/helpers/CartHelpers'
import { environment } from '@/helpers/Environment'
import { imageData, teaserImageUrl, TixImage } from '@/helpers/ImageHelpers'
import { groupBy, indexItemsById, itemIDs } from '@/helpers/IndexHelpers'
import { openModal } from '@/modals/helpers/api'
import { isPastEditHorizon } from '@/helpers/SelfEdit'
import { getTicketsFromCartForOrder } from '@/helpers/TicketEntity'
import type { LanguageStrings } from '@/language/types'
import { ensureLoggedOut, isLoggedIn } from '@/state/Authentication'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { TixTime } from '@/TixTime/TixTime'
import type { EventWithConfig } from '@/api/types/processedEntities'
import { sum } from '@/helpers/Math'

interface CartSessionItem extends EventSession {
  tickets: ItemTicket[]
  actions: Action[]
  eventName: string
  image: TixImage
  startTime: TixTime
  totalPrice: number
  isCancelled: boolean
  isPast: boolean
  isAnchor: boolean
  isRedeemed: boolean
  showRemoveButton: boolean
}

interface Action {
  label: string
  name: string
  // eslint-disable-next-line no-unused-vars
  clickHandler: (item) => void
}

interface ItemTicket {
  name: string
  id: string
  price: number
  isCancelled: boolean
}

/**
 * TODO Handle cart expiry. Booked cart timers are reset everytime the cart is modified, unlike pending carts.
 */
@Component({
  name: 'EditOrderRoute',
  components: { Price, FormInput2 },
})
export default class extends Vue {
  @Prop({ required: true })
  id: string

  order: TicketOrder | null = null
  cart: CartApiResponse | null = null
  openSections: Dict<boolean> = {}

  submitting: boolean = false

  changeSuccessfulMessage: string | null = null
  errorMessage: string | null = null

  t: LanguageStrings['editOrderRoute']

  created() {
    const { id, checkoutComplete } = this.$route.params

    ensureLoggedOut()
      .then(() => fetchOrderAndCart(id, true))
      .then((response) => {
        if (response) {
          this.order = response.order
          this.cart = response.cart
          if (checkoutComplete && !response.cart.cart._data[0].expires_in) {
            this.setChangeSuccessfulMessage()
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          this.$notFound()
        } else {
          this.errorMessage = apiErrorMessageOrRethrow(error)
        }
      })
  }

  cancelOrder() {
    this.errorMessage = null

    openModal({
      name: 'confirm-cancel-modal',
      component: ConfirmModal,
      title: this.t.cancelOrderTitle,
      props: { message: this.t.cancelOrderMessage },
      onClose: (data) => {
        if (data?.confirmed) {
          this.removeTickets(itemIDs(this.cart!.ticket._data))
            .then(this.processCheckoutOnApi)
            .then(this.setChangeSuccessfulMessage)
            .catch(this.handleError)
            .finally(() => {
              this.submitting = false
              this.updateCart()
            })
        }
      },
    })
  }

  get orderCanBeCanceled(): boolean {
    const tickets = this.tickets
    return (
      tickets.length > 0 &&
      tickets.every((ticket) => ticket.face_value === '0') &&
      !tickets.every((ticket) => this.isCancelledTicket(ticket) || this.isPastHorizonTicket(ticket))
    )
  }

  get orderCancelledMessage(): string | null {
    if (this.tickets.length === 0) {
      return this.t.orderCancelled
    }

    return null
  }

  cancelSession(session: CartSessionItem) {
    this.removeTicketsAndUpdate(itemIDs(session.tickets))
  }

  removeTicket(ticket: ItemTicket) {
    this.removeTicketsAndUpdate([ticket.id])
  }

  removeTicketsAndUpdate(ticketIds: string[]) {
    this.errorMessage = null
    this.submitting = true
    this.changeSuccessfulMessage = null

    return this.removeTickets(ticketIds)
      .catch(this.handleError)
      .finally(() => {
        this.submitting = false
        this.updateCart()
      })
  }

  // Use removeTicketsAndUpdate method above unless you plan to chain more promises and don't want to catch the error
  // and update the cart here.
  removeTickets(ticketIds: string[]) {
    const body = { tickets: ticketIds }
    return API.post(`cart/${this.cartId}/remove`, { body, ...cartApiParams() }).then(() => {
      this.$store.commit('addCancelledTicketIds', ticketIds)
    })
  }

  checkout() {
    this.errorMessage = null
    this.submitting = true
    const cartFees = this.cart!.cart_fees._data

    const fee: CartFee = findStripeOrFreeFee(cartFees)
    const outstanding = parseFloat(fee.grand_totals.total_outstanding)
    if (outstanding > 0) {
      this.$router.push(`/manage/${this.order!.id}/confirm`)
      return
    }

    this.processCheckoutOnApi()
      .then(this.setChangeSuccessfulMessage)
      .catch(this.handleError)
      .finally(() => {
        this.submitting = false
        this.updateCart()
      })
  }

  processCheckoutOnApi() {
    const args = {
      // TODO Use embeds instead of this.updateCart() once the API includes cartmods and cart_fees.
      // embed: fetchCartEmbeds,
      body: {
        gateway_id: 'free',
        gateway_data: {},
        send_email: false,
      },
    }

    return API.post(`cart/${this.cartId}/guest_checkout`, args).then(() =>
      API.post(`ticket_order/${this.id}/email`, { body: { log_context: 'order_update' } }),
    )
  }

  setChangeSuccessfulMessage() {
    const ticketRescheduled = this.cart?.ticket._data.find((ticket) => ticket.state === 'in_cart')
    this.changeSuccessfulMessage = ticketRescheduled
      ? this.t.rescheduleSuccessMessage
      : this.t.ticketCancelledSuccessMessage
  }

  discardEdits() {
    this.errorMessage = null
    this.submitting = true

    // Calling DELETE only deletes pending changes
    API.delete(`cart/${this.cartId}`)
      .then(() => {
        const ids = this.tickets.filter((ticket) => ticket.state === 'unbooked').map((ticket) => ticket.id)
        this.$store.commit('removeCancelledTicketIds', ids)
      })
      .catch(this.handleError)
      .finally(() => {
        this.submitting = false
        // The DELETE response does not contain the updated cart, we need to update it manually
        this.updateCart()
      })
  }

  updateCart() {
    return fetchCartAndHandleExpired(this.cartId, true).then((response) => (this.cart = response))
  }

  handleError(e) {
    this.errorMessage = apiErrorMessageOrRethrow(
      e,
      'tickets_too_old:cart_remove_tickets, ticket_not_found:cart_remove_tickets',
    )
  }

  toggleSectionVisibility(id) {
    this.$set(this.openSections, id, !this.openSections[id])
  }

  rescheduleEvent(session) {
    this.$router.push({ path: `/manage/${this.id}/reschedule/${session.id}` })
  }

  normalizeTickets(tickets: Ticket[], groups: Dict<TicketGroup>, types: Dict<TicketType>): ItemTicket[] {
    return tickets
      .map((ticket) => {
        const type = types[ticket.ticket_type_id]
        const group = groups[ticket.ticket_group_id]
        return { ticket, type, group }
      })
      .sort((a, b) => a.type._rank - b.type._rank)
      .sort((a, b) => a.group._rank - b.group._rank)
      .map(({ ticket, type }, index) => {
        const name = ticket.admit_name
          ? `${ticket.admit_name} - ${type.name}`
          : this.indexedTicketName(type.name, index)

        return {
          id: ticket.id,
          name: name,
          price: Number(ticket.face_value),
          isCancelled: this.isCancelledTicket(ticket),
        }
      })
  }

  indexedTicketName(typeName: string, index: number) {
    return this.$t('editOrderRoute.ticketName', { typeName, index: index + 1 }) as string
  }

  get hasAnchoredUpsells() {
    return this.cart!.event_template._data.some(isUpsell)
  }

  getActions(template: EventWithConfig, tickets: ItemTicket[]) {
    const actions: Action[] = []

    if (!this.hasAnchoredUpsells) {
      if (tickets.every((t) => t.price === 0)) {
        actions.push({
          label: 'Cancel',
          name: 'cancel-event',
          clickHandler: this.cancelSession,
        })
      }

      if (template.event_type !== 'single') {
        actions.push({
          label: 'Reschedule',
          name: 'reschedule-event',
          clickHandler: this.rescheduleEvent,
        })
      }
    }

    return actions
  }

  get sessions(): CartSessionItem[] {
    if (this.tickets.length === 0) return []

    const {
      event_template: templates,
      venue,
      ticket_type: allTypes,
      ticket_group: allGroups,
      event_session: eventSessions,
    } = apiEntities(this.cart!)

    const templatesById: Dict<EventWithConfig> = indexItemsById(mergeConfigIntoEvents(templates, this.cart!))
    const venuesById = indexItemsById(venue) as Dict<Venue>
    const ticketsBySession = groupBy('event_session_id', this.tickets)

    const groups = indexItemsById(allGroups)
    const types = indexItemsById(allTypes) as Dict<TicketType>

    return (
      eventSessions
        .filter((session) => Boolean(ticketsBySession[session.id]))
        // Use string-compare on ISO dates.
        .sort((a, b) => (a.start_datetime > b.start_datetime ? 1 : -1))
        .map((session) => {
          const sessionTickets = ticketsBySession[session.id]
          const template = templatesById[session.event_template_id]
          const timezone = venuesById[template.venue_id].timezone
          const tickets = this.normalizeTickets(sessionTickets, groups, types)
          const isCancelled = sessionTickets.every(this.isCancelledTicket)
          const isPastHorizon = isPastEditHorizon(session.start_datetime, timezone)
          const isRedeemed = sessionTickets.some((ticket) => ticket.state === 'redeemed')
          const isEditable = !isCancelled && !isPastHorizon && !isRedeemed

          return {
            ...session,
            tickets: tickets,
            totalPrice: sum(tickets.map((ticket) => ticket.price)),

            // Event properties relevant for this cart session item.
            image: imageData(template, teaserImageUrl),
            eventName: template.name,
            startTime: new TixTime(session.start_datetime, timezone),
            actions: isEditable ? this.getActions(template, tickets) : [],
            isCancelled,
            isPast: new TixTime(session.start_datetime!).isBefore(new TixTime()),
            isRedeemed,
            showRemoveButton: isEditable && !this.hasAnchoredUpsells,
            isAnchor: isAnchor(template),
          }
        })
    )
  }

  isCancelledTicket({ state }: Ticket): boolean {
    return ['cancelled', 'unbooked'].includes(state)
  }

  isPastHorizonTicket(ticket: Ticket): boolean {
    return ticket.start_datetime != null && isPastEditHorizon(ticket.start_datetime, ticket.timezone)
  }

  get unsavedChanges(): boolean {
    return Boolean(this.cart?.cart._data[0].expires_at)
  }

  get showCancelOrderButton(): boolean {
    return this.cart !== null && this.orderCanBeCanceled && !this.unsavedChanges
  }

  get cartId(): string {
    return this.order!.cart_id
  }

  get tickets(): Ticket[] {
    return getTicketsFromCartForOrder(this.cart!, this.id).filter(
      (ticket) => !this.isCancelledTicket(ticket) || this.$store.state.cancelledTicketIds.includes(ticket.id),
    )
  }

  get logoExternalUrl(): string | undefined {
    return environment.sellerMeta?.logo_external_url ?? environment.web.external_urls?.logo
  }

  get isLoggedIn(): boolean {
    return isLoggedIn()
  }

  beforeUnloadListener(e) {
    e.preventDefault()
    // Setting the returnValue prompts the browser to show a default 'changes may not be saved' dialog
    // Most browsers ignore this custom message and use their own, using a custom modal instead is also not supported
    e.returnValue = 'You have unsaved changes to your order that may not be confirmed.'
  }

  @Watch('unsavedChanges')
  unloadModal(value) {
    if (value) {
      window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true })
    } else {
      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true })
    }
  }
}
