
import { API } from '@/api/API'
import MobileFooterPortal from '@/components/cart/MobileFooterPortal.vue'
import SeatMap from '@/components/events/SeatMap.vue'
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import { indexItemsById } from '@/helpers/IndexHelpers'
import { filterQuantitiesByGroups } from '@/helpers/TicketTypeQuantities'
import type { AssignedSeat } from '@/seats/assignments'
import { ttToTGQuantities } from '@/seats/assignments'
import type { TTQuantities } from '@/seats/helpers'
import { getSelectedSeatsMessage, seatSelectionIsComplete } from '@/seats/helpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MobileCartButton from '@/components/elements/MobileCartButton.vue'
import MobileTotal from '@/components/elements/MobileTotal.vue'
import type { Session } from '@/types/Sessions'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'

@Component({
  name: 'SelectSeats',
  components: {
    MobileTotal,
    MobileCartButton,
    SeatMap,
    MobileFooterPortal,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  session: Session

  @Prop({ required: true })
  selectedGroups: LinkedTG[]

  @Prop({ required: true })
  quantities: TicketTypeQuantities

  @Prop({ required: true })
  value: AssignedSeat[] | null

  state: 'open' | 'rendering' | 'closed' = 'closed'
  isMobile: boolean = true

  map: MapGeometry | null = null

  groupFilter: string | null = null

  private seatData: SeatEntity[]

  created() {
    return API.getCached<'seat_map' | 'seat'>(`events/${this.session.event_template_id}/sessions/${this.session.id}/seat_map`, {
      query: { _withgeometry: true },
    })
      .then((response) => {
        this.map = response.seat_map._data[0].map_geometry
        this.seatData = response.seat._data
      })
      .catch(apiErrorMessageOrRethrow)
  }

  get internal() {
    return this.value
  }

  set internal(selectedSeats) {
    if (selectedSeats) {
      // All seats selected, close the map
      this.state = 'closed'
    }
    this.$emit('input', selectedSeats)
  }

  @Watch('state')
  emitState(state: this['state']) {
    this.$emit('seatMapOpen', state === 'open')

    this.$nextTick(() => {
      const el = this.$refs.selectedSeats as HTMLElement | undefined
      if (el && state === 'closed') {
        el.scrollIntoView()
      }
    })

    // Disable scrolling the page when the mobile modal is open
    const body: HTMLBodyElement = document.querySelector('body')!
    body.style.overflow = state === 'open' && this.isMobile ? 'hidden' : 'auto'
  }

  beforeDestroy() {
    // Remove all assigned seats before destroy
    this.$emit('input', [])
  }

  applyFilter(groupId) {
    this.groupFilter = this.groupFilter === groupId ? null : groupId
  }

  get selectMessage() {
    return getSelectedSeatsMessage(this.selectedGroups, this.seatQuantities)
  }

  get seatQuantities(): TTQuantities {
    return filterQuantitiesByGroups(this.quantities, this.selectedGroups)
  }

  openMap(isMobile: boolean) {
    // Slow devices take a long time to create all the DOM elements for large seat maps. Add an intermediate state
    // while elements are being created to show a spinner. The rendering state is not visible if the seat map has few
    // seats and/or the device is fast (like MacBook Pros), because they can do all of that processing in a single
    // animation frame.

    this.state = 'rendering'
    setTimeout(() => {
      this.state = 'open'
      this.isMobile = isMobile
    }, 50)
  }

  get groupsById(): Dict<LinkedTG> {
    return indexItemsById(this.selectedGroups)
  }

  getGroupColor(groupId: string) {
    return `ticket-group-${this.groupsById[groupId].color}-color`
  }

  get sortedSeats(): AssignedSeat[] {
    // Use natural sort (numeric) so that e.g. seats 1-10 are not sorted 1,10,2,3,4...
    return this.value!.sort((a, b) => a.seat.name.localeCompare(b.seat.name, undefined, { numeric: true }))
  }

  get isRendering() {
    return this.state === 'rendering'
  }

  get showFindSeatsButton() {
    if (!this.value) {
      return true
    } else {
      const quantities = ttToTGQuantities(this.seatQuantities, this.selectedGroups)
      return !seatSelectionIsComplete(quantities, this.value)
    }
  }

  get showCart() {
    return this.$store.getters['Cart/ticketCount'] > 0
  }
}
