
import type { ButtonTheme, ButtonType, WalletType } from '@/checkout/helpers/wallets'
import { isWalletPayment } from '@/checkout/helpers/wallets'
import { toKebabCase } from '@/helpers/StringHelpers'
import type { LanguageStrings } from '@/language/types'
import store from '@/store/store'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import type StripeWalletPayment from '@/checkout/stripe/StripeWalletPayment'
import type { PayButtonContext } from '@/helpers/CartHelpers'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'
import { theme } from '@/helpers/Environment'

@Component({ name: 'PayButton' })
export default class PayButton extends Vue {
  usePaymentRequestButton = false

  // TODO Make this dynamic based on tenant and/or cart contents?
  brandedType: ButtonType = 'buy'

  t: LanguageStrings['payButton']

  @Prop({ default: 'default' })
  context: PayButtonContext

  @Prop({ default: 0 })
  paymentDueByCreditCard: number

  @Prop({ default: false })
  disabled: boolean

  @Prop({ default: false })
  processing: boolean

  @Prop()
  supportedWallet: WalletType

  @Prop()
  selectedPaymentType: PaymentMethodName

  @Prop({ default: false })
  isMobile: boolean

  @Prop()
  wallet: StripeWalletPayment | null

  mounted() {
    this.initializeWallet()
  }

  loading = false

  @Watch('supportedWallet')
  initializeWallet() {
    if (this.wallet) {
      this.loading = true

      // this.wallet could be unreachable in race conditions, maybe if `this` has been destroyed?
      const wallet = this.wallet
      wallet.enabled
        .then((value) => {
          if (value != 'none') {
            this.usePaymentRequestButton = true
            const button = wallet.paymentRequestButton(this.brandedTheme, this.brandedType)
            button.mount(this.$refs.brandedWrapper as HTMLDivElement)
            button.on('click', (e) => {
              e.preventDefault()
              this.$emit('click')
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  get usingWallet(): boolean {
    // Cannot use wallets for free carts.
    return isWalletPayment(this.selectedPaymentType) && this.paymentDueByCreditCard > 0
  }

  get usingInternal(): boolean {
    // Use internal pay button when disabled. Google Pay's button can not be disabled.
    return !this.usingWallet || this.disabled || !this.usePaymentRequestButton
  }

  get wrapperClass(): string {
    if (this.usingWallet) {
      const brand = toKebabCase(this.supportedWallet)
      return `wallet-${brand}`
    } else {
      return ''
    }
  }

  get classNames() {
    return { 'branded-button': this.usingWallet }
  }

  get label(): string {
    const labels = this.isMobile ? this.t.mobile : this.t.desktop
    if (this.context === 'checkout') {
      if (this.$store.state.Cart.redeemMembership) {
        return labels.completeRedeemMembershipLabel
      } else if (store.getters['Cart/giftee']) {
        return labels.completeGiftButtonLabel
      } else if (this.hasScheduledPayments) {
        return labels.makePayment
      } else {
        return labels.completeButtonLabel
      }
    } else {
      return labels.proceedButtonLabel
    }
  }

  get brandedTheme(): ButtonTheme {
    return theme?.cartThemeMode || theme?.themeMode ? 'light' : 'dark'
  }

  get hasScheduledPayments(): boolean {
    return store.getters['Cart/hasScheduledPayments']
  }
}
