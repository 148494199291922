import { API } from '@/api/API'
import { linkTypesBackToGroups } from '@/api/Helpers'
import type { LinkedTG } from '@/api/types/processedEntities'
import { showAdvertisedFees } from '@/helpers/Fees'
import { groupBy } from '@/helpers/IndexHelpers'

export function getPricedTicketGroups(id: string, groups: LinkedTG[]): Promise<LinkedTG[]> {
  const payload = groups.map((group) => group.types.map((type) => ({ ticket_type_id: type.id }))).flat()

  if (!showAdvertisedFees() || payload.length === 0) {
    // Don't attempt to get prices if there are no ttIds as the API will return an error if there isn't at least 1 ticket type.
    return Promise.resolve(groups)
  }

  const body = { ticket_types_required: payload }
  return API.post<'ticket_type'>(`events/${id}/pricecheck`, { body }).then((response) => {
    const typesByTG = groupBy('ticket_group_id', response.ticket_type._data)
    // Replace the initial group's types with the new ones returned by the API, that include prices.
    const result: LinkedTG[] = groups.map((group) => {
      return { ...group, types: typesByTG[group.id] } as LinkedTG
    })
    linkTypesBackToGroups(result)
    return result
  })
}
