
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import Price from '@/components/elements/Price.vue'
import {
  getUpcomingAmount,
  getOverdueAmount,
  ProcessedCompletedPayments,
  ProcessedScheduledPayment,
} from '@/helpers/CartHelpers'
import type { PartialPaymentResponse } from '@/checkout/helpers/completing'
import { apiEntities } from '@/api/Helpers'
import { formatCurrency } from '@/helpers/Currency'
import store from '@/store/store'
import { clamp } from '@/helpers/Math'

type PartialPaymentType = 'due' | 'outstanding' | 'other'

interface PartialPaymentOption {
  name: PartialPaymentType
  label: string
}

interface PaymentListItem {
  id: string
  dateTime: string
  amount: string
  status: string
  class: string
}

interface PaymentList {
  title: string
  class: string
  items: PaymentListItem[]
}

@Component({
  name: 'ScheduledPayments',
  components: { Price },
})
export default class extends Vue {
  @Prop()
  partialPaymentResponse: PartialPaymentResponse | null

  selectedPartialPaymentType: PartialPaymentType = 'due'
  showAllPayments = false

  // Set by `anotherAmount` setter
  private inputAmount = 0

  readonly partialPaymentOptions: PartialPaymentOption[] = [
    { name: 'due', label: 'Pay the due amount' },
    { name: 'outstanding', label: 'Pay the outstanding balance on the cart' },
    { name: 'other', label: 'Pay another amount' },
  ]

  created() {
    this.inputAmount = this.dueAmount
  }

  get showOnlyOutstandingOption() {
    return this.dueAmount === this.outstandingBalance
  }

  get displayPartialPaymentOptions() {
    return this.partialPaymentOptions.filter((option) => {
      return this.showOnlyOutstandingOption ? option.name === 'outstanding' : true
    })
  }

  set anotherAmount(v: number) {
    // There are `min` and `max` attributes specified on the form input.
    // However, they only work when using the input's steppers, so we need the following code
    // to restrict the input amount to be in range when a user chooses to type.
    this.inputAmount = clamp(v, this.dueAmount, this.outstandingBalance)
  }

  get anotherAmount(): number {
    return this.inputAmount
  }

  get paymentAmount() {
    switch (this.selectedPartialPaymentType) {
      case 'outstanding':
        return this.outstandingBalance
      case 'other':
        return this.anotherAmount
      default:
        return this.dueAmount
    }
  }

  @Watch('paymentAmount', { immediate: true })
  updateCartPartialPaymentAmount(amount: number) {
    store.commit('Cart/partialPaymentAmount', amount)
  }

  get scheduledPayments(): ProcessedScheduledPayment[] {
    return store.getters['Cart/scheduledPayments']
  }

  get completedPayments(): ProcessedCompletedPayments[] {
    return store.getters['Cart/completedPayments']
  }

  /**
   * Reset payment option after a payment has been made
   */
  @Watch('completedPayments')
  handleCompletedPaymentsChange() {
    this.selectedPartialPaymentType = this.showOnlyOutstandingOption ? 'outstanding' : 'due'
  }

  get dueAmount(): number {
    return getUpcomingAmount(this.scheduledPayments) + this.overdueAmount
  }

  get overdueAmount(): number {
    return getOverdueAmount(this.scheduledPayments)
  }

  get dueDate(): string | undefined {
    return this.scheduledPayments[0]?.dueDate
  }

  get outstandingBalance(): number {
    return store.getters['Cart/outstandingBalance']
  }

  get paymentDueByCreditCard(): number {
    return store.getters['Cart/paymentDueByCreditCard']
  }

  get partialPaymentSuccessMessage(): string | null {
    // There should only be success message when there's a partial payment response
    if (!this.partialPaymentResponse) {
      return null
    }

    const { gateway_audit: payments } = apiEntities(this.partialPaymentResponse)
    const lastPayment = payments[payments.length - 1]
    return `You have successfully made a payment of ${formatCurrency(Number(lastPayment.amount))}.`
  }

  private get displayScheduledPayments(): PaymentListItem[] {
    return this.scheduledPayments.map(({ id, dueDate, amount, status }) => ({
      id,
      dateTime: dueDate,
      amount,
      status,
      class: status.toLowerCase(),
    }))
  }

  private get displayCompletedPayments(): PaymentListItem[] {
    return this.completedPayments.map(({ id, occurredDate, occurredTime, amount, status }) => ({
      id,
      dateTime: `${occurredDate} ${occurredTime}`,
      amount,
      status,
      class: status.toLowerCase(),
    }))
  }

  get paymentLists(): PaymentList[] {
    return [
      {
        title: 'Completed payments',
        class: 'completed-payments',
        items: this.displayCompletedPayments,
      },
      {
        title: 'Scheduled payments',
        class: 'scheduled-payments',
        items: this.displayScheduledPayments,
      },
    ]
  }
}
