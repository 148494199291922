import { toKebabCase } from '@/helpers/StringHelpers'

const stories = [
  {
    name: 'Session Selection States',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/SelectSessionStatesStory.vue'),
  },
  {
    name: 'Session Selection Legend',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/SelectSessionLegendStory.vue'),
  },
  {
    name: 'Date-first',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/ReserveDateFirstStory.vue'),
  },
  {
    name: 'BuyMembership',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/BuyMembershipStory.vue'),
  },
  {
    name: 'Named members',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/NamedMembersStory.vue'),
  },
  {
    name: 'Select quantities',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/SelectQuantitiesStory.vue'),
  },
  {
    name: 'SelectGroupQuantities',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/SelectGroupQuantitiesStory.vue'),
  },
  {
    name: 'Elements',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/ElementsStory.vue'),
  },
  {
    name: 'CartWidget',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/CartWidgetStory.vue'),
  },
  {
    name: 'MembersMenu',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/MembersMenuStory.vue'),
  },
  {
    name: 'CartExpiryModal',
    component: () => import(/* webpackChunkName: "group-stories" */ './CartExpiryModalStory.vue'),
  },
  {
    name: 'TermsCheckbox',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/TermsCheckboxStory.vue'),
  },
  {
    name: 'TermsModal',
    component: () => import(/* webpackChunkName: "group-stories" */ './TermsModalStory.vue'),
  },
  {
    name: 'LoginModal',
    component: () => import(/* webpackChunkName: "group-stories" */ './LoginModalStory.vue'),
  },
  {
    name: 'MembershipModal',
    component: () => import(/* webpackChunkName: "group-stories" */ './MembershipModalStory.vue'),
  },
  {
    name: 'LoginOrGuestModal',
    component: () => import(/* webpackChunkName: "group-stories" */ './LoginOrGuestModalStory.vue'),
  },
  {
    name: 'DeleteCartOrCheckoutModal',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/DeleteCartOrCheckoutModalStory.vue'),
  },
  {
    name: 'Stepper',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/StepperStory.vue'),
  },
  {
    name: 'Modal',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/ModalStory.vue'),
  },
  {
    name: 'EventListingItem',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/EventListingItemStory.vue'),
  },
  {
    name: 'Calendar states',
    component: () => import(/* webpackChunkName: "group-stories" */ './CalendarStatesStory.vue'),
  },
  {
    name: 'Calendar picker',
    component: () => import(/* webpackChunkName: "group-stories" */ './CalendarPickerStory.vue'),
  },
  {
    name: 'EventDetailErrorMessages',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/EventDetailErrorMessagesStory.vue'),
  },
  {
    name: 'DateSelector States',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/DateSelectorStatesStory.vue'),
  },
  {
    name: 'Legend',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/TixLegendStory.vue'),
  },
  {
    name: 'PasswordRequirements States',
    component: () => import(/* webpackChunkName: "group-stories" */ '@/stories/PasswordRequirementsStory.vue'),
  },
]

export default stories
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((story) => ({
    path: toKebabCase(story.name),
    ...story,
  }))
