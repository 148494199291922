
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'PasswordRequirement',
})
export default class extends Vue {
  @Prop({ required: true })
  valid: boolean

  @Prop({ required: true })
  notEmpty: boolean

  @Prop({ default: false })
  showError: boolean

  get error() {
    return this.notEmpty && !this.valid && this.showError
  }

  get success() {
    return this.notEmpty && this.valid
  }

  get icon() {
    if (this.success) return 'CheckmarkIcon'
    else if (this.error) return 'TimesIcon'
    else return 'CircleIcon'
  }
}
