import type { CalendarApiResponse } from '@/api/calendar'
import type { EventDetails } from '@/api/types/processedEntities'
import type { Badge, CalendarDate, CalendarMonthData, DayData } from '@/calendar/types'
import { formatCurrency } from '@/helpers/Currency'
import { getDateAnnotations } from '@/helpers/DynamicMessages'
import { getTotalFeesIfEnabled } from '@/helpers/Fees'
import { handleUnlimitedCapacity } from '@/helpers/SessionHelpers'
import { toKebabCase } from '@/helpers/StringHelpers'
import { languageItem } from '@/language/helpers'
import { twoDigits } from '@/TixTime/helpers'
import { TixDate } from '@/TixTime/TixDate'
import { TixTime } from '@/TixTime/TixTime'

export function findClosestEnabledDate(dates: Dict<DayData>, initial: TixDate, offset: number): TixDate | void {
  const keys = Object.keys(dates)

  // Get the first and last date in the provided dates list.
  const start = new TixDate(keys[0])
  const end = new TixDate(keys[keys.length - 1])

  if (!initial.isBetween(start, end, undefined, '[]')) {
    throw new Error('Tix: findClosestEnabledDate(): Initial date was outside the range')
  }

  // Step through dates until an enabled date is found or the needle is out of range.
  // The offset determines the size and direction of each step.
  let result = initial
  do {
    result = result.add(offset, 'day')
    if (dates[result.date]?.enabled) {
      return result
    }
  } while (result.isBetween(start, end, undefined, '()'))
}

/**
 * Gets the month elements in the viewport and scrolls to previous/next one.
 */
export function scrollButtonHandler(visibleMonthIDs: Set<string>, direction: number): void {
  const wrapper = document.querySelector('.date-picker-wrapper')!

  function getMonthElement(id: string): HTMLElement {
    return wrapper.querySelector(`.calendar .picker-month[data-id="${id}"]`)!
  }

  // Scroll the wrapper into the viewport first.
  wrapper.scrollIntoView({
    behavior: 'auto',
    block: 'nearest',
    inline: 'nearest',
  })

  // Convert the set to an array and order the IDs in it.
  const keys = [...visibleMonthIDs].sort((a, b) => a.localeCompare(b))

  const nextMonth = getMonthElement(keys[keys.length - 1]).nextElementSibling
  const previousMonth = getMonthElement(keys[0]).previousElementSibling
  const monthElement = direction > 0 ? nextMonth : previousMonth

  // There is no previous/next month when all available dates are in the same month.
  if (monthElement) {
    monthElement.scrollIntoView({
      behavior: 'smooth',
      // The `nearest` options means that it will scroll to the nearest edge of the element.
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

export function processCalendarApiResponse(apiResponse: CalendarApiResponse, event: EventDetails): DayData[] {
  const timezone = event.venue.timezone
  const today = TixDate.today(timezone)

  return apiResponse.map((day: EventBasicDateData) => {
    const badges = new Set<Badge>()
    const tooltip: string[] = []

    if (day.status === 'sold_out') {
      tooltip.push(languageItem('availabilityStatus').soldOut)
    }

    // TODO Convert getDateAnnotations() to use TixDate.
    const annotations = getDateAnnotations(new TixTime(day.date, timezone), event) ?? []
    for (const annotation of annotations) {
      tooltip.push(annotation.message)
      badges.add({ style: annotation.style })
    }

    const date = new TixDate(day.date)
    const price = getPrice(day)

    return {
      date,
      annotations,
      price,
      available: getAvailable(day),
      status: day.status,
      className: toKebabCase(day.status),
      enabled: day.status === 'available',
      badges: Array.from(badges),
      tooltip: tooltip.join('. '),
      longFormat: formatTodayTomorrowDate(date, today),
      ariaLabel: ariaLabel(date, day.status, tooltip, price),
      pricePrefix: getPricePrefix(day),
    }
  })
}

export function calendarDatesIndex(days: DayData[]): Dict<DayData> {
  const result: Dict<DayData> = {}
  for (const day of days) {
    result[day.date.date] = day
  }
  return result
}

export function getMonths(data: Dict<DayData>): Dict<CalendarMonthData> {
  function firstAvailable(dates: string[]): TixDate {
    const date = dates.find((date) => 'available' === data[date].status)!
    return new TixDate(date)
  }

  const dates = Object.keys(data)

  const first = firstAvailable(dates)
  const last = firstAvailable(dates.reverse())

  const result: Dict<CalendarMonthData> = {}
  for (let i = first; i.isSameOrBefore(last, 'month'); i = i.add(1, 'month')) {
    const monthId = i.format('YYYY-MM')
    result[monthId] = {
      id: monthId,
      year: Number(i.format('YYYY')),
      month: Number(i.format('MM')),
      formatted: i.format('MMMM YYYY'),
      weeks: getWeeksInMonth(monthId, data),
    }
  }
  return result
}

function formatTodayTomorrowDate(date: TixDate, today: TixDate): string {
  if (date.isSame(today)) {
    return date.format('[Today], MMMM D')
  }

  const tomorrow = today.add(1, 'day')
  if (date.isSame(tomorrow)) {
    return date.format('[Tomorrow], MMMM D')
  }

  return date.format('dddd, MMMM D')
}

function getPrice(day: EventBasicDateData): number | undefined {
  if (hasPricing(day)) return Number(day.min_price) + getTotalFeesIfEnabled(day.min_price_fees?.outside)
}

function getAvailable(day: EventBasicDateData): number | undefined {
  // API returns availability and pricing information together. As of June 2024 it is not possible to get one without the other.
  if (hasPricing(day)) return handleUnlimitedCapacity(day.availability.capacity) - day.availability.used_capacity
}

function ariaLabel(date: TixDate, status: DateAvailabilityStatus, tooltip: string[], price?: number): string {
  const formatted = date.format('LONGER_DATE')

  if (status === 'sold_out') return `${formatted} is sold out`
  if (status !== 'available') return `${formatted} is not available`

  const label: string[] = [formatted, ...tooltip]

  if (price) {
    const formatted = price === 0 ? 'free' : formatCurrency(price)
    label.push(`The price is ${formatted}`)
  }

  return label.join('. ')
}

function getPricePrefix(day: EventBasicDateData): string | undefined {
  if (hasPricing(day)) return day.pricePrefix
}

function hasPricing(day: EventBasicDateData): day is EventDateData {
  return 'min_price' in day
}

/**
 * @param monthId Formatted YYYY-MM
 * @param data
 */
function getWeeksInMonth(monthId: string, data: Dict<DayData>): CalendarDate[][] {
  // Initialize a two-dimensional 6x7 array to represent a month of a calendar grid.
  //   - 6 rows/weeks. A month that starts on Saturday 1st and finishes on Monday 31st covers 6 calendar weeks.
  //   - 7 columns; days per week.
  // All values initialize to undefined.
  const result: CalendarDate[][] = [
    // prettier-ignore Keep each item on its own.
    new Array(7),
    new Array(7),
    new Array(7),
    new Array(7),
    new Array(7),
    new Array(7),
  ]

  const daysInMonth = new TixDate(`${monthId}-15`).daysInMonth()
  let week = 0

  // Iterate each day of the month by week-of-month (zero indexed) and weekday (Sunday is zero).
  for (let i = 1; i <= daysInMonth; i++) {
    const dayNumber = twoDigits(i)
    const date = new TixDate(`${monthId}-${dayNumber}`)
    const dayOfWeek = Number(date.format('d'))

    // Increment week index on Sundays, except the first day of the month.
    if (dayOfWeek === 0 && i > 1) week++

    // Specify the CalendarDate details for each day of the month.
    result[week][dayOfWeek] = {
      formatted: date.date,
      dayOfMonth: i,
      // Include the API DayData, if there is any. Ensures TypeScript understands it might not exist with `undefined`.
      data: data[date.date] ?? undefined,
    }
  }

  return result
}
